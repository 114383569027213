import {  useRoutes } from 'react-router-dom'


import { Landing_2 } from 'src/layouts/LandingLayout'


export default function Router() {
  const routeElements = useRoutes([
    {
      path: '',
      element: <Landing_2 />
    }
  ])

  return routeElements
}
