import s from './landing_2.module.scss'
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Landing_2() {
  return (
    <div>
      <div className='container mx-auto max-w-[700px] relative'>
        <img src='/assets/images/landing_2/section1.png' alt='' className='w-full max-w-[700px] block mx-auto' />
        <a
          href='https://t.me/+xh67F9WxyTIyZGM9'
          title='고객센터 텔레그램 바로가기'
          target='_blank'
          className={`!absolute top-[80%] left-1/2 -translate-x-1/2 w-[60%] max-w-[398px] ${s.shadow}`}
          rel='noreferrer'
        >
          <img
            src='/assets/images/landing_2/telegram-button.png?t=2'
            alt='고객센터 텔레그램 바로가기'
            title='고객센터 텔레그램 바로가기'
            width='1135'
            height='232'
            className='w-full object-cover'
          />
        </a>
      </div>
      <div className='relative container mx-auto max-w-[700px] pt-0 pb-[140px]'>
        <img src='/assets/images/landing_2/section2.png' alt='' className='absolute top-0 left-0 w-full h-full' />
        <div className={`relative !mx-auto rounded-lg !w-[90%] top-7 p-[5px]`}>
          <img
            src='/assets/images/landing_2/tv-section-v2_new.png?t=2'
            alt=''
            className={`relative top-5 mx-auto z-20 rounded-lg ${s.tvSection}`}
          />
          <a
            href='https://www.youtube.com/channel/UCzBFez0SZkduRSkywLgJ7Hw'
            title='지노엔터 키노 YOUTUBE 바로가기'
            target='_blank'
            rel='noreferrer'
            className='absolute z-40 text-center left-[18.5%] rounded-[10px] top-[29%] flex items-center justify-center w-1/2'
          >
            <img src='/assets/images/landing_2/btn-1.png' alt='채널1' className='rounded-[14px]' />
          </a>
          <a
            href='https://www.youtube.com/channel/UCKSKhjY4po-Q_nl9AhesONA'
            title='지노엔터 키노 YOUTUBE 바로가기'
            target='_blank'
            rel='noreferrer'
            className='absolute z-40 text-center left-[18.5%] rounded-[10px] top-[49%] flex items-center justify-center w-1/2'
          >
            <img src='/assets/images/landing_2/btn-2.png' alt='채널1' className='rounded-[14px]' />
          </a>
        </div>
        <a
          href='https://t.me/sinoent'
          title='고객센터 텔레그램 바로가기'
          target='_blank'
          className={`!absolute -bottom-[5%] left-1/2 -translate-x-1/2 w-[60%] max-w-[398px] ${s.shadow}`}
          rel='noreferrer'
        >
          <img
            src='/assets/images/landing_2/telegram-button-v2.png?t=2'
            alt='고객센터 텔레그램 바로가기'
            title='고객센터 텔레그램 바로가기'
            width='1135'
            height='232'
            className='w-full object-cover'
          />
        </a>
      </div>
      <div className='container mx-auto max-w-[700px]'>
        <img src='/assets/images/landing_2/section3_cheat.jpg' alt='' className='w-full max-w-[700px] block mx-auto' />
        <img src='/assets/images/landing_2/section3_new.png' alt='' className='w-full max-w-[700px] block mx-auto' />
      </div>
    </div>
  )
}
